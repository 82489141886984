main.l-main {
  width: 100%;
  height: 100%;
  padding: 0px 0 0 90px;
  -webkit-transition: padding 0.5s ease-in-out;
  -moz-transition: padding 0.5s ease-in-out;
  -ms-transition: padding 0.5s ease-in-out;
  -o-transition: padding 0.5s ease-in-out;
  transition: padding 0.5s ease-in-out;
}

main.l-main .content-wrapper {
  padding: 0px;
  height: 100%;
}

main.l-main .content-wrapper .page-content {
  border-top: 1px solid #d0d0d0;
  padding-top: 25px;
}

main.l-main .content-wrapper--with-bg .page-content {
  background: #f4f6f9;
  border-radius: 3px;
  border-top: 0px;
  padding: 10px 25px 25px 0px;
}

.report-page .date_bg {
  border-radius: 0px;
  box-shadow: none;
}

.report-page .calendar_icon-report,
.report-page .calendar_icon-report:hover,
.report-page .calendar_icon-report:focus {
  border: 0px;
  background-color: transparent;
}

.report-page td.r-no-data {
  background-color: #ffffff;
  padding: 30px !important;
  text-align: center;
}

.fourty-fluid {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.header-icons-group .dropdown {
  padding-right: 15px !important;
}

.header-icons-group .dropdown span {
  font-size: 11px !important;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: " ";
}

.clearfix:after {
  clear: both;
}

.teammembers--selectdrop .ui-select-multiple.ui-select-bootstrap div {
  border-bottom: 1px solid #dddddd;
}

.teammembers--selectdrop .ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  position: relative;
}

.teammembers--selectdrop .assignteam-select .ui-select-multiple.ui-select-bootstrap.form-control:before {
  content: "\f107";
  font-family: FontAwesome;
  font-size: 17px;
  position: absolute;
  bottom: 0;
  right: 0;
  color: #817c7c;
}

.text-muted {
  color: #999999 !important;
}

.assignteam-select .ui-select-multiple.ui-select-bootstrap.form-control {
  width: auto;
  display: inline-block;
  min-width: 280px;
}

.selected_names .ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
  padding: 0px 5px 0px 5px;
  background: transparent !important;
  color: #f5821f;
  border: 1px solid #f5821f !important;
}

.selected_names .ui-select-multiple.ui-select-bootstrap .ui-select-match .close {
  line-height: 17px;
  opacity: 0.5;
}

.selected_names .ui-select-search {
  width: auto !important;
}

.selected_names .form-control {
  box-shadow: none;
  border-bottom: 1px solid #ddd;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
}

.listservice-selectdropdown .ui-select-container {
  width: 100% !important;
}

.practise-li {
  font-size: 9px;
  height: 25px;
  max-width: 25px;
  width: 25px;
  min-width: 25px;
  padding-top: 6px;
}

.python {
  background: #fff;
  color: #f07f1e;
  border: 1px solid #f07f1e;
}

.form_date {
  width: 200px;
  ;
}

.r-logo {
  display: block;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  opacity: 0.9;
  z-index: 1080;
}


#status {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  background-image: url(../../loader.gif);
  background-repeat: no-repeat;
  background-position: center;
  margin: -100px 0 0 -100px;
  background-size: 70%;
}

.pr-l-active {
  background: #f07f1e;
  color: #fff !important;
  border: 1px solid #f07f1e;
  border-radius: 30px;
}

.alert_report_row {
  background: rgba(253, 247, 50, 0.31) !important;
  padding: 3px;
  border-radius: 3px;
}

.green_report_row {
  background: rgba(62, 216, 15, 0.31) !important;
  padding: 3px;
  border-radius: 3px;
}

.btn-average {
  background-color: #fd7e14;
  border-color: #fd7e14;
  color: #fff;
}

.btn-average:hover,
.btn-average:focus,
.btn-average:active,
.btn-average.active {
  background-color: #fd7e14;
  border-color: #fd7e14;
  color: #fff;
}

.btn-average.disabled:hover,
.btn-average[disabled]:hover,
fieldset[disabled] .btn-average:hover,
.btn-average.disabled:focus,
.btn-average[disabled]:focus,
fieldset[disabled] .btn-average:focus,
.btn-average.disabled:active,
.btn-average[disabled]:active,
fieldset[disabled] .btn-average:active,
.btn-average.disabled.active,
.btn-average[disabled].active,
fieldset[disabled] .btn-average.active {
  background-color: #fd7e14;
  border-color: #fd7e14;
  color: #fff;
}

.accor_alignment .accr_heading {
  display: inline-block;
  text-align: left;
  width: 100%;
}

.invoice--report {
  background: #fff;
}

.pro_listng .border_bottom {
  border-bottom: 1px solid #ddd;
}

.table_head_bor_btm {
  border-bottom: 2px solid #d7d7d7 !important;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: #21aeca;
}

.accordion-title:hover {
  background-color: #3ab4cc;
}

.accordion-title,
.accordion-content {
  padding: 1rem;
}

.accordion-content {
  background-color: #39b9d2;
}

.accor_levels.accordion-body td span {
  display: block;
  text-align: left;
}

.invoice--report.inventory_allo_table .accordian_servicesdiv .table {
  margin: 5px 0px !important;
}

.inventory_allo_tablereact .nav-tabs .nav-link.active,
.inventory_allo_tablereact .nav-tabs .nav-link:hover {
  background: transparent;
  border: 1px solid #e1e1e1 !important;
  color: orange;
}

.inventory_allo_tablereact .nav-tabs .nav-link:focus {
  border: 1px solid transparent;
}

.inventory_allo_tablereact .nav-tabs .nav-link {
  color: #666666;
}

.inventory_allo_tablereact .accor_alignment .panel-default {
  padding: 0 !important;
}

.page-content.minht {
  min-height: 650px;
}

.table-scroller {
  overflow: auto;
  width: 100% !important;
}

.invoice--report.inventory_allo_table .table tr td span.resourcenames_span {
  float: none;
  display: block;
  width: 100%;
  text-align: left;
}

.invoice--report.inventory_allo_table .accordian_servicesdiv .table {
  margin: 5px 0px !important;
  width: 100%;
}

.pending_danger_report_row {
  background: rgba(255, 171, 171, 0.31) !important;
  padding: 3px;
  border-radius: 3px;
}

.intern-bg {
  background: #aa66cc;
}

.intern-text {
  color: #aa66cc;
}

.date_picker_align.datepickerweekly .react-datepicker-wrapper {
  padding: 4px;
}

.date_picker_align.datepickerweekly .react-datepicker__close-icon {
  right: 20px;
}

.red-batch-bg {
  background: red;
}

.black-batch-bg {
  background: black;
}

.yellow-batch-bg {
  background: #EEBB10;
}

.text-color-red {
  color: red;
}

.text-color-blue {
  color: #2b699c;
}

.text-color-black {
  color: black;
}

.text-color-yellow {
  color: #EEBB10;
}

.text-color-green {
  color: #009688;
}

span.spantooltip[title]:hover {
  width: 200px;
}

.tooltip-inner {
  background-color: #f5821f !important;
  text-align: left !important;
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #f5821f !important;
}

.sub_total-row .PigmentIndigo-text {
  font-weight: bold;
  color: #000;
}

.invoice--report.inventory_allo_table .thead1 {
  font-size: 12px !important;
  text-transform: capitalize !important;
  background: #083052;
  color: #fff !important;
  text-align: center !important;
  border: 1px solid #000 !important;
  padding: 3px 6px;
  vertical-align: middle;
  line-height: normal;
}

.grand_total-row td {
  background: #083052;
  color: white;
}

.grand_total-row td span {
  color: white !important;
}

.bench_data {
  color: #0d6efd;
  text-decoration: underline;
  cursor: pointer;
}

.invoice--report.inventory_allo_table .table tr.main_top_one td {
  background: #fff;
}

.invoice--report.inventory_allo_table .table tr td,
.invoice--report.inventory_allo_table .table tr.main_top_one td {
  border: 1px solid #000 !important;
}

.practice_tooltip.tooltip {
  width: 180px !important;
}

.practice_tooltip.tooltip span {
  display: block;
  width: 100%;
}

.h-28 {
  height: 28px;
}

.invoice--report.inventory_allo_table .thead_csm {
  font-size: 12px !important;
  text-transform: capitalize !important;
  background: #f8cca6;
  color: #000 !important;
  text-align: center !important;
  border: 1px solid #000 !important;
  padding: 3px 4px;
  vertical-align: middle;
  line-height: normal;
}

.check {
  background-color: lightgray;
  border-radius: 50%;
}

.reserve_data {
  color: red;
  text-decoration: none;
  cursor: pointer;
}

.invntry-mw-th th.thead1.scroll:nth-child(3) {
  min-width: 10px;
}

.invntry-mw-th th.thead1 {
  min-width: 140px;
}

.invntry-mw-th th.thead1.scroll:nth-child(5),
.invntry-mw-th th.thead1.scroll:nth-child(6) {
  min-width: 80px;
}

.service_unit_bg {
  background: #b4d89ac2 !important;
}

.rs-dropdown-item:focus,
.rs-dropdown-item:active,
.rs-dropdown-item:hover,
.rs-dropdown-item-active {
  background-color: #f5821f !important;
  color: #fff !important;
}

.rs-btn {
  width: 300px !important;
  font-size: 16px !important;
}

.rs-dropdown-item {
  width: 300px !important;
}

.pmo-report table {
  border-collapse: collapse;
  width: 100%;
}

.pmo-report th,
td {
  text-align: left;
  padding: 8px;
}

.pmo-report td {
  text-align: left;
  padding: 8px;
  color: #2b2525e0;
}

.pmo-report tr:nth-child(even) {
  background-color: #f2f2f2;
}

.pmo-truncate {
  white-space: nowrap !important;
  overflow-x: hidden !important;
  text-overflow: ellipsis !important;
  color: #2b2525e0;
  display: block;
}

.swal2-title {
  line-height: 25px;
}

.acv-report .table>:not(:first-child) {
  border: 1px solid #a2a2a28c;
}

.custom-select option:hover {
  background-color: orange;
  color: white;
}

.hovered {
  background-color: orange;
  color: white;
}

.custom-modal-title {
  color: #f5821f;
  margin-left: auto;
  font-family: 'Conv_bellgothicstd-bold';
  font-size: 16px;
  /* Change the color to your desired value */
}

.custom-modal-button {
  background-color: #f5821f;
  padding: 6px, 12px;
  width: 40px;
  height: 50px;

}

.potential-button {
  text-align: end !important;
  right: 23px !important;
  padding: 10px !important;
  position: absolute !important;
  color: #f5821f !important;
}

.potential-heading {
  padding: -3px !important;
  right: 100px !important;
  width: 508px !important;

}

.potential-footer {
  padding: 6px;
  position: inherit;
  right: 216px;
}

.potential-button {
  text-align: end !important;
  right: 23px !important;
  padding: 10px !important;
  position: absolute !important;
  color: #f5821f !important;
}

.potential-heading {
  padding: -3px !important;
  right: 100px !important;
  width: 508px !important;

}

.potential-footer {
  padding: 6px;
  position: inherit;
  right: 0;
}

@media (min-width: 576px) {
  .potentialHeader .modal-dialog {
    max-width: 450px;
  }
}

.modal-body {
  font-size: 15px !important;
}

.delete-modal {
  justify-content: space-between !important;
}

.msallocation--report {
  position: absolute;
  right: 28px;
  width: 61px;
  padding: 0;
  top: 1px;
  z-index: 1;
  height: -11px;
  margin-top: -8px;
}

.msallocation--report-year {
  position: absolute;
  right: -100px;
  width: 77px;
  padding: 0;
  top: 1px;
  z-index: 1;
  margin-top: -8px;
}

.msallocation--report-year-col {
  position: absolute;
  right: -55px;
  width: 73px;
  padding: 0;
  top: 1px;
  z-index: 1;
}

.msallocation--report-generate {
  position: absolute;
  right: 225px;
}

.potentialDate {
  border: 1px solid #cfccc6 !important;
}

.datepicker-control-section-status {
  /* Apply the same styling as input fields */
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  /* Additional styling for the date picker */
  background-color: white;
  margin-left: 12px;
  /* Make sure the background is white */

}


.yearfilter {

  input,
  select {
    width: 89px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-left: 5px;
  }

}


.pmo-report .table>:not(:first-child) {
  border-top: 0px solid currentColor;
}

.quarter-heading {
  margin-top: 15px;

}

.modal-backdrop {
  background-color: transparent !important;
}


.targetPopupModal .modal-dialog {
  margin-top: 32px !important;
}


.invoice--report.inventory_allo_table.inventory_allo_tablereact {
  position: relative;
  top: 30px;
}

.invoice--report.inventory_allo_table.inventory_allo_tablereact .potential_button {
  position: absolute;
  right: 91px;
  top: 15px;
}